<template>
  <banner
    :backgroundVideo="$maxxpm.config.home.banner.video"
    :backgroundImage="$maxxpm.config.home.banner.image"
    class="main-banner flex justify-content-center flex-grow-1 flex-column">
    <h1 class="text-white text-center">
      {{ $maxxpm.config.home.banner.title }}
    </h1>
    <h2 class="text-white text-center">
      {{ $maxxpm.config.home.banner.subtitle }}
    </h2>
    <div class="p-4 text-center">
      <Button @click="routeToAvail" label="Availability" rounded></Button>
    </div>
  </banner>
  <div class="grid container mt-4">
    <div class="col-12 md:col-6">
      <div>
        <h2 class="text-primary">About Us</h2>
        <p v-for="paragraph of $maxxpm.config.home.aboutUs.paragraphs" :key="paragraph">
          {{ paragraph }}
        </p>
      </div>
    </div>
    <div class="col-12 md:col-6">
      <DeferredContent>
        <img
          :src="$maxxpm.config.home.aboutUs.image.src"
          :alt="$maxxpm.config.home.aboutUs.image.alt"
          class="img-fluid about-us-image" />
      </DeferredContent>
    </div>
  </div>
  <div class="grid grid-nogutter">
    <div class="bg-secondary col-12 mt-4 mb-4">
      <h2 class="text-white m-5 text-center">
        {{ $maxxpm.config.home.floorplanTitle }}
      </h2>
    </div>
    <div class="floorplan-row col-12" v-for="floorplan of $maxxpm.config.home.floorplans" :key="floorplan.name">
      <div class="grid grid-nogutter">
        <div
          :class="{
            'md:col-6': floorplan.hasWalkthrough,
          }"
          class="col-12 p-3"
          v-if="floorplan.hasImage || floorplan.hasIcons || floorplan.name">
          <div v-if="floorplan.hasIcons" class="floorplan-icons">
            <span v-for="icon of floorplan.icons" :key="icon">
              <span class="icon-text">{{ icon.text }}</span>
              <i :class="icon.icon" class="floorplan-icon"></i>
            </span>
          </div>
          <p class="text-primary text-center" v-if="floorplan.name">
            {{ floorplan.name }}
          </p>
          <div v-if="floorplan.hasImage">
            <DeferredContent class="flex justify-content-center">
              <img :src="floorplan.image.src" :alt="floorplan.image.alt" class="img-fluid" />
            </DeferredContent>
          </div>
        </div>
        <div
          :class="{
            'md:col-6': floorplan.image,
          }"
          class="col-12 mp-ifr p-3"
          v-if="floorplan.hasWalkthrough">
          <iframe
            :src="floorplan.walkthrough.src"
            frameborder="0"
            allowfullscreen="allowfullscreen"
            height="97%"
            width="100%"
            :title="floorplan.walkthrough.title"></iframe>
        </div>
      </div>
    </div>
  </div>
  <banner :backgroundImage="$maxxpm.config.home.midContainerImage" class="mid-container">
    <h2 class="text-white mt-0">Find your Happy Home</h2>
    <Button label="Availability" @click="routeToAvail" severity="secondary" rounded></Button>
  </banner>
  <div class="grid container" id="qualifications">
    <div class="col-12">
      <div class="grid">
        <div class="col-12 text-center">
          <h2 class="text-primary">Rental Qualifications & Standards</h2>
          <p class="qualifications-heading">
            Thank you for choosing to apply with MAXX Property Management. Please review the rental requirements and
            minimum standards below before you begin the application process.
          </p>
          <p class="qualifications-heading">
            FOR SECURITY REASONS, IF YOU PAUSE TOO LONG DURING THE ONLINE APPLICATION PROCESS, YOU MAY LOSE YOUR
            PROGRESS.
          </p>
        </div>
      </div>
    </div>
    <div class="col-12">
      <div class="flex align-items-start" v-for="qual in qualifications" :key="qual.qualification">
        <img v-show="!qual.noMarker" :src="$maxxpm.config.listMarkerSrc" class="mr-2 mt-1 li-marker" role="none" />
        <div :style="qual.noMarker ? 'margin-left: 38px' : ''">
          <p v-html="qual.qualification" class="mt-0"></p>
          <ul v-if="qual.sublist">
            <li v-for="item in qual.sublist" :key="item" v-html="item"></li>
            <li v-if="qual.subsublist" class="sublist">
              <ul>
                <li v-for="item in qual.subsublist" :key="item" v-html="item"></li>
              </ul>
            </li>
            <li v-for="item in qual.sublist2" :key="item" v-html="item"></li>
            <li v-if="qual.subsublist2" class="sublist">
              <ul>
                <li v-for="item in qual.subsublist2" :key="item" v-html="item"></li>
              </ul>
            </li>
            <li v-for="item in qual.sublist3" :key="item" v-html="item"></li>
          </ul>
          <div v-if="qual.button">
            <div class="flex justify-content-center pet-btn-col">
              <a href="https://maxx.petscreening.com/" target="_blank">
                <Button label="Create an Animal or Pet Profile" rounded></Button>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="grid grid-nogutter">
    <div v-for="image of $maxxpm.config.home.imgRow" :key="image.src" class="p-0 col-12 md:col-4">
      <DeferredContent>
        <img :src="image.src" :alt="image.alt" class="img-row" />
      </DeferredContent>
    </div>
  </div>
  <div class="grid container grid-nogutter" id="animal-policy">
    <div class="col-12">
      <h2 class="text-primary text-center">Animal Policy</h2>
    </div>
    <div class="col-12">
      <div class="grid">
        <div class="col-12 md:col-6">
          <div v-for="policy in $maxxpm.config.home.animalPolicy.policies" :key="policy" class="flex align-items-start">
            <img :src="$maxxpm.config.listMarkerSrc" class="mr-2 mt-2 li-marker" role="none" />
            <p class="mt-1">
              {{ policy }}
            </p>
          </div>
          <p>* non-refundable</p>
        </div>
        <div class="col-12 md:col-6">
          <DeferredContent>
            <img
              :src="$maxxpm.config.home.animalPolicy.img.src"
              class="img-fluid"
              :alt="$maxxpm.config.home.animalPolicy.img.alt" />
          </DeferredContent>
        </div>
      </div>
    </div>
    <div class="col-12">
      <h3 class="display-6 mt-2">Breed Restrictions</h3>
      <p class="display-7">(If the animal is a mixed breed with one of the breeds below, it is still restricted)</p>
    </div>
    <ul class="flex flex-wrap">
      <li v-for="breed in $maxxpm.config.home.animalPolicy.breedRestrictions" :key="breed" class="breed-container">
        <p class="breed-li">{{ breed }}</p>
      </li>
    </ul>
  </div>
</template>

<script setup>
// @ is an alias to /src
import { onMounted, ref, inject } from "vue";
import { useRouter } from "vue-router";
import { Banner } from "@maxxpm/vue-components";
import axios from "axios";
import DeferredContent from "primevue/deferredcontent";
import Button from "primevue/button";

const router = useRouter();

const $maxxpm = inject("$maxxpm");
const qualifications = ref(null);

onMounted(async () => {
  const q = await axios.get("https://cdn.maxxpm.com/Qualifications.json");

  qualifications.value = q.data;
});

function routeToAvail() {
  router.push({ path: "/availability" });
}
</script>

<style lang="scss" scoped>
:deep(.main-banner) {
  padding: 6rem 1rem;
}

.container {
  width: 95vw;
  max-width: 1200px;
  margin: auto;
}
.mp-ifr {
  min-height: 400px;
}

:deep(.mid-container) {
  height: 400px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0;
}

.img-row {
  width: 100%;
  height: 380px;
  object-fit: cover !important;
}

.breed-container {
  width: 33%;
  text-align: start;
}

.breed-container::marker {
  color: var(--primary-color);
}

.breed-li {
  margin-bottom: 0;
}

.sublist {
  list-style-type: none;
}

:deep(.pet-btn-col) > a {
  color: var(--primary-color);
}

:deep(li) > a {
  text-decoration: underline;
}

.floorplan-row {
  width: 90%;
  max-width: 1200px;
  margin: auto;
  margin-bottom: 3rem;
  box-shadow: 0px 0px 25px #888;
  border-radius: 1rem;
  padding: 2rem 0.5rem;
  background: white;
}
.about-us-image {
  max-height: 375px;
}
.qualifications-heading {
  font-weight: bold;
}

.li-marker {
  max-width: 35px;
}

.floorplan-icons {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  color: var(--primary-color);
  width: 100%;
  margin-bottom: 1.5rem;
}

.icon-text {
  font-size: 1.25rem;
  margin-right: 0.5rem;
}

.floorplan-icon {
  font-size: 1.25rem;
}

@media (max-width: 768px) {
  .breed-container {
    width: 100%;
  }
}
</style>
